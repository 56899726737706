$(function () {
    if (!$('.market-opportunity-page').length && !$('.market-opportunity-landing-page').length) {
        return;
    }
    
    $('#mo-search-button').on('click', function (e) {
        e.preventDefault();
        var form = $('#mo-search');
        if (form.length > 0) {
            var formData = new FormData(form[0]);
            var sectionUrl = formData.get('section-url');
            var url = (sectionUrl?.length > 0 ? sectionUrl : form.attr("action")) + "?" + form.serialize();
            window.location.href = url;
        }
        return false;
    });

    const interestedInBtn = $("#interestedInBtn");
    const interestedInSpinner = $("#interestedInSpinner");
    const interestedInThankYou = $("#interestedInThankYou");
    const interestedInError = $("#interestedInError");

    interestedInBtn.on('click', function () {

        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'InterestedIn',
                'digitalService': 'MarketOpportunities',
                'signedIn': true
            });
        }

        interestedInError.toggleClass("d-none", true);
        interestedInSpinner.toggleClass("d-none", false);
        let onError = () => {
            interestedInSpinner.toggleClass("d-none", true);
            interestedInError.toggleClass("d-none", false);
            interestedInBtn.prop("disabled", false);
        };

        let marketOpportunityId = interestedInBtn.attr('data-marketOpportunityId');
        if (!interestedInBtn.prop("disabled")) {
            interestedInBtn.prop("disabled", true);
            interestedInSpinner.show();
            $.get(`/api/v1/mo/AddMarketOpportunityAssociation?marketOpportunityId=${marketOpportunityId}`)
                .done(function (result) {
                    if (result.toString().toLowerCase() === (true).toString().toLowerCase())
                    {
                        interestedInSpinner.toggleClass("d-none", true);
                        interestedInThankYou.toggleClass("d-none", false);
                        interestedInBtn.html(interestedInBtn.attr('data-text-success'));
                    }
                    else
                    {
                        onError();
                    }
                })
                .fail(onError);
        }
    });

    $("#mo-deny-terms-button").on('click', function () {
        // Let's disable the modal buttons since the logout process can take time...
        $("#mo-deny-terms-button").prop("disabled", true);
        $("#mo-deny-terms-button").addClass("disabled");
        $("#mo-accept-terms-button").prop("disabled", true);
        $("#mo-accept-terms-button").addClass("disabled");
        window.location.href = "/login/logout";
    });

    $("#mo-accept-terms-button").on('click', function () {
        $(".modal.show").removeClass("show").addClass("fade").hide();
        $(".modal-backdrop").remove();
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days
        let expires = "expires=" + d.toUTCString();
        document.cookie = "MO-AcceptTerms=true; " + expires + "; path=/"; 
    });
})